import request from '@/utils/request'

export async function getActivityList() {
  return request({
    url: '/activity',
    method: 'get',
  })
}

export async function editActivity(data) {
  return request({
    url: '/activity/' + data?.id,
    method: 'post',
    data,
  })
}

export async function createActivity(data) {
  console.log('----- createActivity')
  console.log(data)
  return request({
    url: '/activity',
    method: 'post',
    data,
  })
}

export async function getActivity(id) {
  return request({
    url: '/activity/' + id,
    method: 'get',
  })
}

export async function deleteActivity(id) {
  return request({
    url: '/activity/' + id,
    method: 'delete',
  })
}
