import {
  getActivityList,
  editActivity,
  createActivity,
  getActivity,
  deleteActivity,
} from '@/api/activity'

const state = () => ({
  activities: [],
  currentActivity: {},
})

const getters = {
  activities: (state) => state.activities,
  currentActivity: (state) => state.currentActivity,
}

const mutations = {
  setActivities(state, activities) {
    state.activities = activities
    // localStorage.setItem('storeCase', JSON.stringify(state))
  },
  setCurrentActivity(state, currentActivity) {
    state.currentActivity = currentActivity
    // localStorage.setItem('storeCase', JSON.stringify(state))
  },
}

const actions = {
  async getActivityList({ commit }) {
    try {
      const data = await getActivityList()
      if (data.data) {
        commit('setActivities', data.data)
      } else {
        commit('setActivities', [])
      }
    } catch (e) {
      commit('setActivities', [])
      console.log('exception')
      console.log(e)
    }
  },
  async editActivity({ dispatch }, postBody) {
    try {
      const data = await editActivity(postBody)
      if (data.status === 200) {
        dispatch('getActivityList')
      }
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
  async createActivity({ dispatch }, postBody) {
    try {
      const data = await createActivity(postBody)
      if (data.status === 200) {
        dispatch('getActivityList')
      }
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
  async getActivity({ commit }, fid) {
    try {
      const data = await getActivity(fid)
      if (data.data) {
        commit('setCurrentActivity', data.data)
      } else {
        commit('setCurrentActivity', {})
      }
    } catch (e) {
      commit('setCurrentActivity', {})
      console.log('exception')
      console.log(e)
    }
  },
  async deleteActivity({ dispatch }, fid) {
    try {
      const data = await deleteActivity(fid)
      if (data.status === 200) {
        dispatch('getActivityList')
      }
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
}

export default { state, getters, mutations, actions }
