<template>
  <div class="vab-logo">
    <div class="logo-area">
      <a-row>
        <a-col justify="start">
          <img class="logo-image" src="@/assets/image/retekfoundLogo.svg" />
        </a-col>
        <a-col>
          <a-row align="middle" style="height: 50%; padding-left: 5px">
            <img
              class="logo-text-image"
              src="@/assets/image/retekfoundLogoText.svg"
            />
          </a-row>
          <a-row align="middle" style="height: 50%; padding-left: 5px">
            <p class="p-text">內容管理平台</p>
          </a-row>
        </a-col>
      </a-row>
    </div>

    <!-- <vab-icon v-if="logo" :icon="logo"></vab-icon> -->
    <!-- <span class="anticon"></span> -->
    <!-- <span>{{ title }}</span> -->
  </div>
</template>

<script>
  //import VabIcon from '@/layout/vab-icon'
  import { mapGetters } from 'vuex'
  export default {
    name: 'VabLogo',
    //components: { VabIcon },
    computed: {
      ...mapGetters({
        logo: 'settings/logo',
        title: 'settings/title',
      }),
    },
  }
</script>
<style lang="less" scoped>
  .vab-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    //margin: 16px 5px;
    overflow: hidden;
    font-size: 15px;
    color: #fff;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    background-color: #fff;
    .logo-area {
      width: 200px;
      height: 42px;
      .logo-image {
        max-width: 56px;
        max-height: 56px;
      }
      .logo-text-image {
        max-width: 68px;
        max-height: 18px;
      }
      .p-text {
        width: 100px;
        height: 18px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height */

        color: #1d1d1b;
      }
    }
  }
</style>
