import {
  getDashboardItemList,
  editDashboardItem,
  createDashboardItem,
  getDashboardItem,
  deleteDashboardItem,
} from '@/api/dashboardItem'

const state = () => ({
  dashboardItems: [],
  currentDashboardItem: {},
})

const getters = {
  dashboardItems: (state) => state.dashboardItems,
  currentDashboardItem: (state) => state.currentDashboardItem,
}

const mutations = {
  setDashboardItems(state, dashboardItems) {
    state.dashboardItems = dashboardItems
    // localStorage.setItem('storeCase', JSON.stringify(state))
  },
  setCurrentDashboardItem(state, currentDashboardItem) {
    state.currentDashboardItem = currentDashboardItem
    // localStorage.setItem('storeCase', JSON.stringify(state))
  },
}

const actions = {
  async getDashboardItemList({ commit }) {
    try {
      const data = await getDashboardItemList()
      if (data.data) {
        commit('setDashboardItems', data.data)
      } else {
        commit('setDashboardItems', [])
      }
    } catch (e) {
      commit('setDashboardItems', [])
      console.log('exception')
      console.log(e)
    }
  },
  async editDashboardItem({ dispatch }, postBody) {
    try {
      const data = await editDashboardItem(postBody)
      if (data.status === 200) {
        dispatch('getDashboardItemList')
      }
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
  async createDashboardItem({ dispatch }, postBody) {
    try {
      const data = await createDashboardItem(postBody)
      if (data.status === 200) {
        dispatch('getDashboardItemList')
      }
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
  async getDashboardItem({ commit }, fid) {
    try {
      const data = await getDashboardItem(fid)
      if (data.data) {
        commit('setCurrentDashboardItem', data.data)
      } else {
        commit('setCurrentDashboardItem', {})
      }
    } catch (e) {
      commit('setCurrentDashboardItem', {})
      console.log('exception')
      console.log(e)
    }
  },
  async deleteDashboardItem({ dispatch }, fid) {
    try {
      const data = await deleteDashboardItem(fid)
      if (data.status === 200) {
        dispatch('getDashboardItemList')
      }
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
}

export default { state, getters, mutations, actions }
