<template>
  <div class="vab-avatar">
    <a-dropdown>
      <span class="ant-dropdown-link">
        <a-avatar :src="avatar" />
        {{ account }}
        <DownOutlined />
      </span>
      <template v-slot:overlay>
        <a-menu>
          <a-menu-item @click="logout">
            {{ $t('RightMenu.logout') }}
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </div>
</template>

<script>
  import { recordRoute } from '@/config'
  import { DownOutlined } from '@ant-design/icons-vue'

  import { mapActions, mapGetters } from 'vuex'
  export default {
    name: 'VabAvatar',
    components: { DownOutlined },
    data() {
      return {}
    },
    computed: {
      ...mapGetters({
        avatar: 'user/avatar',
        account: 'user/account',
        // webConfig: 'user/webConfig',
      }),
    },
    methods: {
      async logout() {
        await this.$store.dispatch('user/logout')
        await localStorage.clear()
        if (recordRoute) {
          const fullPath = this.$route.fullPath
          this.$router.push(`/login?redirect=${fullPath}`)
          this.$router.go(0)
        } else {
          this.$router.push('/login')
          this.$router.go(0)
        }
      },
      ...mapActions({
        changeLanguage: 'settings/changeLanguage',
        setLoadingStatus: 'settings/setLoadingStatus',
        setLoadingText: 'settings/setLoadingText',
      }),
    },
  }
</script>
<style lang="less">
  .vab-avatar {
    .ant-dropdown-link {
      display: block;
      min-height: 64px;
      cursor: pointer;
    }
  }
</style>
