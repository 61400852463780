import axios from 'axios'
import {
  baseURL,
  contentType,
  debounce,
  requestTimeout,
  successCode,
  tokenName,
} from '@/config'
import store from '@/store'
import qs from 'qs'
import router from '@/router'
import { isArray } from '@/utils/validate'
import { message } from 'ant-design-vue'

let loadingInstance

/**
 * @author noname
 * @description 處理code異常
 * @param {*} code
 * @param {*} msg
 */
const handleCode = (code, msg) => {
  switch (code) {
    case 401:
      message.error('授權失敗，請重新登入')
      // await store.dispatch('user/logout')
      // await localStorage.clear()
      // router.push(`/login`)
      // router.go(0)
      break
    case 403:
      router.push({ path: '/401' }).catch(() => {})
      break
    default:
      message.error(msg || `伺服器異常 ${code}`)
      break
  }
}

/**
 * @author noname
 * @description axios初始化
 */
console.log(process.env.VUE_APP_BASE_URL)
const instance = axios.create({
  baseURL,
  timeout: requestTimeout,
  headers: {
    'Content-Type': contentType,
  },
})

/**
 * @author noname
 * @description axios請求攔截器
 */
instance.interceptors.request.use(
  (config) => {
    if (store.getters['user/accessToken'])
      config.headers[tokenName] = 'Bearer ' + store.getters['user/accessToken']
    if (
      config.data &&
      config.headers['Content-Type'] ===
        'application/x-www-form-urlencoded;charset=UTF-8'
    )
      config.data = qs.stringify(config.data)
    if (debounce.some((item) => config.url.includes(item))) {
      //這裏寫加載動畫
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

/**
 * @author noname
 * @description axios回響攔截器
 */
instance.interceptors.response.use(
  (response) => {
    if (loadingInstance) loadingInstance.close()
    //console.log(response)
    const { data, config, status } = response
    const code = status
    const { errorCode, errorMessage } = data
    // 操作正常Code數組
    const codeVerificationArray = isArray(successCode)
      ? [...successCode]
      : [...[successCode]]
    // 是否操作正常, bypass excel file download
    if (
      codeVerificationArray.includes(code) &&
      (response.headers['content-type'] === 'application/vnd.openxmlformats' ||
        response.headers['Content-Type'] === 'application/vnd.openxmlformats')
    ) {
      return response
    } else if (
      codeVerificationArray.includes(code) &&
      errorCode === undefined
    ) {
      return data
    } else {
      handleCode(code, errorMessage)

      return Promise.reject(
        'Request Intercept:' +
          JSON.stringify({ url: config.url, errorCode, errorMessage }) ||
          'Error'
      )
    }
  },
  async (error) => {
    if (loadingInstance) loadingInstance.close()
    const { response } = error
    let errMsg = error.message || ''
    if (error.response && error.response.data) {
      const { status, data } = response
      handleCode(status, data.errorMessage || errMsg)
      return Promise.reject(error)
    } else {
      if (errMsg === 'Network Error') {
        errMsg = '網路異常'
      }
      if (errMsg.includes('timeout')) {
        errMsg = '網路異常(逾時)'
      }
      if (errMsg.includes('Request failed with status code')) {
        const code = errMsg.substr(errMsg.length - 3)
        errMsg = '伺服器異常：' + code
      }
      if (errMsg) {
        message.error(errMsg || `伺服器異常`)
      } else {
        message.error(`伺服器異常`)
      }

      return Promise.reject(error)
    }
  }
)

export default instance
