/**
 * @author noname
 * @description 登录、获取用户信息、退出登录、清除accessToken逻辑，不建议修改
 */
import { login, getUserList } from '@/api/user'
import { getLocalStorage, setLocalStorage } from '@/utils/localStorage'

import {
  getAccessToken,
  removeAccessToken,
  setAccessToken,
} from '@/utils/accessToken'
//import { title } from '@/config'
import { message } from 'ant-design-vue'

const state = () => ({
  accessToken: getAccessToken(),
  account: getLocalStorage('account') || '',
  avatar: '',
  // webConfig: getLocalStorage('webConfig') || [],
})
const getters = {
  accessToken: (state) => state.accessToken,
  account: (state) => state.account,
  avatar: (state) => state.avatar,
  // webConfig: (state) => state.webConfig,
}
const mutations = {
  // setWebConfig(state, webConfig) {
  //   state.webConfig = webConfig
  //   setLocalStorage('webConfig', state.webConfig)
  // },
  /**
   * @author noname
   * @description 设置accessToken
   * @param {*} state
   * @param {*} accessToken
   */
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken
    setAccessToken(accessToken)
  },
  /**
   * @author noname
   * @description 设置用户名
   * @param {*} state
   * @param {*} account
   */
  setAccount(state, account) {
    state.account = account
    setLocalStorage('account', state.account)
  },
  /**
   * @author noname
   * @description 设置头像
   * @param {*} state
   * @param {*} avatar
   */
  setAvatar(state, avatar) {
    state.avatar = avatar
  },
}
const actions = {
  // async getWebConfig({ commit }, account) {
  //   const data = await getWebConfig(account)
  //   commit('setWebConfig', data)
  // },
  /**
   * @author noname
   * @description 登录拦截放行时，设置虚拟角色
   * @param {*} { commit, dispatch }
   */
  setVirtualRoles({ commit, dispatch }) {
    dispatch('acl/setFull', true, { root: true })
    commit('setAvatar', 'https://i.gtimg.cn/club/item/face/img/2/15922_100.gif')
    commit('setAccount', 'admin(未開登入攔截)')
  },
  /**
   * @author noname
   * @description 登录
   * @param {*} { commit }
   * @param {*} userInfo
   */
  async login({ commit }, { userInfo, errorMessage }) {
    const data = await login(userInfo)
    const accessToken = data?.data?.['token']
    if (accessToken) {
      commit('setAccessToken', accessToken)
      // const hour = new Date().getHours()
      // const thisTime =
      //   hour < 8
      //     ? '早上好'
      //     : hour <= 11
      //     ? '上午好'
      //     : hour <= 13
      //     ? '中午好'
      //     : hour < 18
      //     ? '下午好'
      //     : '晚上好'
      const account = userInfo && userInfo.email ? userInfo.email : ''
      commit('setAccount', account)
      // notification.open({
      //   message: `歡迎登入${title}`,
      //   description: `${thisTime}！`,
      //   //description: t('LI001.loginFail'),
      // })
      // const webConfig = await getWebConfig(userInfo.account)
      // if (webConfig && webConfig.result) {
      //   commit('setWebConfig', webConfig.result)
      //   commit('routes/changeRouteByWebConfig', webConfig.result, {
      //     root: true,
      //   })
      // }
    } else {
      message.error(errorMessage)
    }
  },
  /**
   * @author noname
   * @description 获取用户信息接口 这个接口非常非常重要，如果没有明确底层前逻辑禁止修改此方法，错误的修改可能造成整个框架无法正常使用
   * @param {*} { commit, dispatch, state }
   * @returns
   */
  // async getUserInfo({ commit, dispatch, state }) {
  //   const data = await getUserInfo(state.accessToken)
  //   if (!data) {
  //     message.error(`驗證失敗，請重新登入...`)
  //     return false
  //   }
  //   let { username, avatar, roles, ability } = data
  //   if (username && roles && Array.isArray(roles)) {
  //     dispatch('acl/setRole', roles, { root: true })
  //     if (ability && ability.length > 0)
  //       dispatch('acl/setAbility', ability, { root: true })
  //     commit('setUsername', username)
  //     commit('setAvatar', avatar)
  //   } else {
  //     message.error('無法取得用戶資訊')
  //   }
  // },

  // Default set admin role
  async getUserInfo({ dispatch, state }) {
    if (!state.accessToken) {
      message.error('Authorization Failed')
      return false
    }
    let roles = ['admin']
    let ability = ['READ', 'WRITE', 'DELETE']

    dispatch('acl/setRole', roles, { root: true })
    dispatch('acl/setAbility', ability, { root: true })
  },

  /**
   * @author noname
   * @description 退出登录
   * @param {*} { dispatch }
   */
  async logout({ dispatch }) {
    //await logout(state.accessToken)
    await dispatch('resetAll')
  },
  /**
   * @author noname
   * @description 重置accessToken、roles、ability、router等
   * @param {*} { commit, dispatch }
   */
  async resetAll({ dispatch }) {
    await dispatch('setAccessToken', '')
    await dispatch('acl/setFull', false, { root: true })
    await dispatch('acl/setRole', [], { root: true })
    await dispatch('acl/setAbility', [], { root: true })
    removeAccessToken()
  },
  /**
   * @author noname
   * @description 设置token
   */
  setAccessToken({ commit }, accessToken) {
    commit('setAccessToken', accessToken)
  },

  async getUsers() {
    const data = await getUserList()
    console.log('---- getUsers')
    console.log(data)
  },
}
export default { state, getters, mutations, actions }
