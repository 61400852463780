/**
 * @author noname
 * @description 路由拦截状态管理，目前两种模式：all模式与intelligence模式，其中partialRoutes是菜单暂未使用
 */
import { asyncRoutes, constantRoutes } from '@/router'
import { getRouterList } from '@/api/router'
import { convertRouter, filterRoutes } from '@/utils/routes'
import { getLocalStorage, setLocalStorage } from '@/utils/localStorage'

const state = () => ({
  routes: [],
  partialRoutes: [],
  routeWebConfig: getLocalStorage('routeWebConfig') || [],
})
const getters = {
  routes: (state) => state.routes,
  partialRoutes: (state) => state.partialRoutes,
}
const mutations = {
  setRoutes(state, routes) {
    state.routes = routes
    if (!state.routeWebConfig || state.routeWebConfig.length < 0) {
      return
    }
    state.routes.forEach((menuItem) => {
      for (let i = 0; i < state.routeWebConfig.length; i++) {
        if (
          menuItem.webConfigId &&
          menuItem.webConfigId === state.routeWebConfig[i].id
        ) {
          menuItem.hidden =
            state.routeWebConfig[i]?.privilege?.show === true ? false : true
          menuItem.webConfigIsEnable =
            state.routeWebConfig[i]?.privilege?.enable === true ? false : true
          break
        }
      }
    })
  },
  setPartialRoutes(state, routes) {
    state.partialRoutes = routes
  },
  changeRouteByWebConfig(state, webConfig) {
    state.routeWebConfig = webConfig
    setLocalStorage('routeWebConfig', webConfig)
    state.routes.forEach((menuItem) => {
      for (let i = 0; i < webConfig.length; i++) {
        if (menuItem.webConfigId && menuItem.webConfigId === webConfig[i].id) {
          menuItem.hidden =
            webConfig[i]?.privilege?.show === true ? false : true

          menuItem.webConfigIsEnable =
            webConfig[i]?.privilege?.enable === true ? false : true
          break
        }
      }
    })
  },
}
const actions = {
  /**
   * @author noname
   * @description intelligence模式设置路由
   * @param {*} { commit }
   * @returns
   */
  async setRoutes({ commit }) {
    const finallyRoutes = filterRoutes([...constantRoutes, ...asyncRoutes])
    commit('setRoutes', finallyRoutes)
    return [...asyncRoutes]
  },
  /**
   * @author noname
   * @description all模式设置路由
   * @param {*} { commit }
   * @returns
   */
  async setAllRoutes({ commit }) {
    let { data } = await getRouterList()
    if (data[data.length - 1].path !== '*')
      data.push({ path: '*', redirect: '/404', hidden: true })
    const asyncRoutes = convertRouter(data)
    const finallyRoutes = filterRoutes([...constantRoutes, ...asyncRoutes])
    commit('setRoutes', finallyRoutes)
    return [...asyncRoutes]
  },
  /**
   * @author noname
   * @description 画廊布局、综合布局设置路由
   * @param {*} { commit }
   * @param accessedRoutes 画廊布局、综合布局设置路由
   */
  setPartialRoutes({ commit }, accessedRoutes) {
    commit('setPartialRoutes', accessedRoutes)
  },
}
export default { state, getters, mutations, actions }
