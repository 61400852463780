import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/layout'

export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login'),
    hidden: true,
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/403'),
    hidden: true,
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
    hidden: true,
  },
]
export const asyncRoutes = [
  {
    webConfigId: 'activity',
    webConfigIsEnable: false,
    hidden: false,
    path: '/',
    component: Layout,
    redirect: '/index',
    meta: {
      title: '輪播廣告',
      icon: 'file-list-2-line',
      affix: true,
    },
    children: [
      {
        path: 'index',
        name: 'Index',
        component: () => import('@/views/activity'),
        meta: {
          title: '輪播廣告',
          icon: 'file-list-2-line',
          affix: true,
        },
      },
    ],
  },
  {
    webConfigId: 'dashboardItem',
    webConfigIsEnable: false,
    hidden: false,
    path: '/dashboardItem',
    component: Layout,
    redirect: '/dashboardItem',
    meta: {
      title: '首頁功能',
      icon: 'layout-grid-line',
      affix: true,
    },
    children: [
      {
        path: 'dashboardItem',
        name: 'dashboardItem',
        component: () => import('@/views/dashboardItem'),
        meta: {
          title: '首頁功能',
          icon: 'layout-grid-line',
          affix: true,
        },
      },
    ],
  },
  {
    path: '/*',
    redirect: '/404',
    hidden: true,
  },
]
const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes,
})

export default router
