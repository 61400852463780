<template>
  <div id="vue-admin-beautiful">
    <a-config-provider :locale="antDesignLanguage">
      <router-view />
      <!-- <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </router-view> -->
    </a-config-provider>
  </div>
</template>
<script>
  import zhTW from 'ant-design-vue/es/locale-provider/zh_TW'
  import enUS from 'ant-design-vue/es/locale-provider/en_US'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'App',
    data() {
      return {
        antDesignLanguage: enUS,
      }
    },
    mounted() {
      if (this.language === 'tw' || this.language === 'en') {
        this.$i18n.locale = this.language
        this.antDesignLanguage = this.language === 'tw' ? zhTW : enUS
      } else {
        if (
          navigator.language.toLowerCase() == 'zh-tw' ||
          navigator.language.toLowerCase() == 'zh' ||
          navigator.language.toLowerCase() == 'zh-hk'
        ) {
          this.$i18n.locale = 'tw'
          this.antDesignLanguage = zhTW
        } else {
          this.$i18n.locale = 'en'
          this.antDesignLanguage = enUS
        }
        this.changeLanguage(this.$i18n.locale)
      }
      //console.log(navigator.language)
      //console.log(navigator.languages)
    },
    computed: {
      ...mapGetters({
        language: 'settings/language',
      }),
    },
    methods: {
      ...mapActions({
        changeLanguage: 'settings/changeLanguage',
      }),
    },
    watch: {
      language: function () {
        this.antDesignLanguage = this.language === 'tw' ? zhTW : enUS
      },
    },
  }
</script>
<style lang="less">
  @import '~@/vab/styles/vab.less';
</style>
