import request from '@/utils/request'

export async function getDashboardItemList() {
  return request({
    url: '/dashboardItem',
    method: 'get',
  })
}

export async function editDashboardItem(data) {
  return request({
    url: '/dashboardItem/' + data?.id,
    method: 'post',
    data,
  })
}

export async function createDashboardItem(data) {
  console.log('----- createDashboardItem')
  console.log(data)
  return request({
    url: '/dashboardItem',
    method: 'post',
    data,
  })
}

export async function getDashboardItem(id) {
  return request({
    url: '/dashboardItem/' + id,
    method: 'get',
  })
}

export async function deleteDashboardItem(id) {
  return request({
    url: '/dashboardItem/' + id,
    method: 'delete',
  })
}
